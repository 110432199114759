export default (app, store) => {
  app.directive('permission', {
    mounted(el, binding, vnode) {
      console.log('el',el);
      console.log('binding',binding);
      if (!binding.value) return;
      const button_permissions = store.state.navigator.button_permissions;
      console.log('button_permissions',button_permissions);
      const found = button_permissions.find(p => p.selector === binding.value);
      if (!found) {
        el.parentNode.removeChild(el);
      }
    }
  });
};
