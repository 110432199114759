import moment from "moment";

export const System_Permission_Codes = {
  SuperAdmin: 'SYSTEM-001'
};

export const Common_Date_Range_Shortcuts = [
  {
    text: '上一周',
    value: () => {
      const current = moment();
      const last_week = current.subtract(7, 'days');
      const start = last_week.startOf('week').valueOf();
      const end = last_week.endOf('week').valueOf();
      return [start, end];
    }
  },
  {
    text: '上个月',
    value: () => {
      const current = moment();
      const start_of_this_month = current.startOf('month');
      const last_month_one_day = start_of_this_month.subtract(2, 'days');
      const start = last_month_one_day.startOf('month').valueOf();
      const end = last_month_one_day.endOf('month').valueOf();
      return [start, end];
    }
  },
  {
    text: '去年',
    value: () => {
      const current = moment();
      const start_of_this_year = current.startOf('year');
      const last_year_one_day = start_of_this_year.subtract(1, 'year');
      const start = last_year_one_day.startOf('year').valueOf();
      const end = last_year_one_day.endOf('year').valueOf();
      return [start, end];
    }
  }
];

export const Charging_Pile_Status = [
  { label: '空闲', value: 'free', class: 'fc-tag-empty', key: 'free', color: '#1BD495' },
  { label: '升级中', value: 'upgrading', class: 'fc-tag-empty', key: 'upgrading', color: '#1BD495' },
  { label: '空余', value: 'partial_free', class: 'fc-tag-empty', key: 'partial_free', color: '#1e8ad5' },
  { label: '充电中', value: 'in_use', class: 'fc-tag-charging', key: 'in_use', color: '#3F93FF' },
  { label: '故障', value: 'abnomal', class: 'fc-tag-hitch', key: 'abnomal', color: '#FF9D3E' },
  { label: '离线', value: 'offline', class: 'fc-tag-offline', key: 'offline', color: '#B3B3B3' },
  { label: '锁定', value: 'locked', key: 'locked' }
];
export const OTA_Task_Status=[
  { label: '空闲', value: 'free', class: 'fc-tag-empty', key: 'free', color: '#1BD495' },
  { label: '空余', value: 'partial_free', class: 'fc-tag-empty', key: 'partial_free', color: '#1e8ad5' },
  { label: '离线', value: 'offline', class: 'fc-tag-offline', key: 'offline', color: '#B3B3B3' },
]
export const OTA_Publish_Status=[
  { label: '已完成', value: 'completed', class: 'fc-tag-empty', key: 'completed', color: '#1BD495' },
  { label: '未开始', value: 'no_start', class: 'fc-tag-charging', key: 'no_start', color: '#1e8ad5' },
  { label: '升级中', value: 'upgrading', class: 'fc-tag-charging', key: 'upgrading', color: '#1e8ad5' },
  { label: '已取消', value: 'cancelled', class: 'fc-tag-offline', key: 'cancelled', color: '#B3B3B3' },
  { label: '升级失败', value: 'failed', class: 'fc-tag-offline', key: 'failed', color: '#F56C6C' },
]
export const Charging_Type = {
  dc_quick: '直流快充',
  dc_slow: '直流慢充',
  ac_quick: '交流快充',
  ac_slow: '交流慢充'
};

export const Gun_Type = [
  { label: '空闲', value: 'free', class: 'fc-tag-empty', key: 'free', color: '#1BD495' },
  { label: '充电中', value: 'in_use', class: 'fc-tag-charging', key: 'in_use', color: '#3F93FF' },
  { label: '故障', value: 'abnomal', class: 'fc-tag-hitch', key: 'abnomal', color: '#FF9D3E' },
  { label: '未拔枪', value: 'offline', class: 'fc-tag-offline', key: 'finish_occupied', color: '#B3B3B3' },
  { label: '锁定', value: 'locked', key: 'locked' }
];

export const Feedkback_Status = [
  { label: '处理完成', value: 'completed', class: 'fc-tag-empty', key: 'completed', color: '#1BD495' },
  { label: '处理中', value: 'processing', class: 'fc-tag-charging', key: 'processing', color: '#3F93FF' },
  { label: '忽略', value: 'ignored', class: 'fc-tag-offline', key: 'ignored', color: '#B3B3B3' },
  { label: '待处理', value: 'pending', class: 'fc-tag-hitch', key: 'pending', color: '#FF9D3E' }
];

export const Feedkback_Status_Map = {
  completed: '处理完成',
  processing: '处理中',
  ignored: '忽略',
  pending: '待处理'
};

export const Charging_Pile_Standard_Array = [
  { label: '国标', value: 'gb' },
  { label: '欧标', value: 'ob' }
];

export const Charging_Pile_Standard_Map = {
  gb: '国标',
  ob: '欧标'
};

export const Fg_Type = [
  { label: '峰电', value: 'peak' },
  { label: '谷电', value: 'valley' },
  { label: '平电', value: 'others' },
  { label: '尖电', value: 'pointed' },
  { label: '深谷电', value: 'deep' }
];

export const Fg_Type_Map = {
  peak: '峰电',
  valley: '谷电',
  others: '平电',
  pointed: '尖电',
  deep: '深谷电'
};

export const Charging_Rule_Type = {
  electricity: 'electricity',
  timeout: 'timeout'
};

export const Order_Status = [
  { key: 'NEW', value: 'NEW', label: '未开始充电' },
  { key: 'CHARGING_PREPARING', value: 'CHARGING_PREPARING', label: '准备充电' },
  { key: 'CHARGING_IN_PROGRESS', value: 'CHARGING_IN_PROGRESS', label: '正在充电' },
  { key: 'CHARGING_COMPLETE', value: 'CHARGING_COMPLETE', label: '充电完成' },
  { key: 'PAYMENT_PENDING', value: 'PAYMENT_PENDING', label: '等待支付' },
  { key: 'PAYMENT_IN_PROGRESS', value: 'PAYMENT_IN_PROGRESS', label: '支付中' },
  { key: 'PAYMENT_COMPLETE', value: 'PAYMENT_COMPLETE', label: '支付完成' },
  { key: 'PAYMENT_FAILED', value: 'PAYMENT_FAILED', label: '支付失败' },
  { key: 'CHARGING_EXCEPTION', value: 'CHARGING_EXCEPTION', label: '充电异常' },
  { key: 'CLOSED', value: 'CLOSED', label: '关闭' }
];

export const Order_Status_Map = {
  NEW: '未开始充电',
  CHARGING_PREPARING: '准备充电',
  CHARGING_IN_PROGRESS: '正在充电',
  CHARGING_COMPLETE: '充电完成',
  PAYMENT_PENDING: '等待支付',
  PAYMENT_IN_PROGRESS: '支付中',
  PAYMENT_COMPLETE: '支付完成',
  PAYMENT_FAILED: '支付失败',
  CHARGING_EXCEPTION: '充电异常',
  CLOSED: '关闭'
};
/**
 * 流水类型
 * @type {{Map: {ORDER_SETTLEMENT: string, WALLET_WITHDRAW: string, WALLET_RECHARGE: string, ORDER_PREPAID: string, "AUTO_REFUND_AMOUNT\"": string, MANUAL_REFUND_AMOUNT: string, UNKNOWN: string, ORDER_SETTLEMENT_ADJUSTMENT: string}}}
 */
export const Flowing_Type={
  Map:{
    'ORDER_SETTLEMENT':'订单结算',
    'ORDER_PREPAID':'订单预付',
    'ORDER_SETTLEMENT_ADJUSTMENT':'订单结算（补缴）',
    'AUTO_REFUND_AMOUNT':'退回金额（系统自动）',
    'MANUAL_REFUND_AMOUNT':'退回金额（人工）',
    'WALLET_RECHARGE':'钱包充值',
    'WALLET_WITHDRAW':'钱包提现',
    'UNKNOWN':'未知流水子类型',
  }
}
export const Order_Status_Map_Button=[
  { label: '未开始充电', value: 'NEW', class: 'fc-tag-empty', key: 'NEW', color: '#3F93FF' },
  { label: '准备充电', value: 'CHARGING_PREPARING', class: 'fc-tag-empty', key: 'CHARGING_PREPARING', color: '#3F93FF' },
  { label: '正在充电', value: 'CHARGING_IN_PROGRESS', class: 'fc-tag-empty', key: 'CHARGING_IN_PROGRESS', color: '#3F93FF' },
  { label: '充电完成', value: 'CHARGING_COMPLETE', class: 'fc-tag-charging', key: 'CHARGING_COMPLETE', color: '#4CAF50' },
  { label: '等待支付', value: 'PAYMENT_PENDING', class: 'fc-tag-charging', key: 'PAYMENT_PENDING', color: '#FF8400' },
  { label: '支付中', value: 'PAYMENT_IN_PROGRESS', class: 'fc-tag-charging', key: 'PAYMENT_IN_PROGRESS', color: '#FF8400' },
  { label: '支付完成', value: 'PAYMENT_COMPLETE', class: 'fc-tag-charging', key: 'PAYMENT_COMPLETE', color: '#4CAF50' },
  { label: '支付失败', value: 'PAYMENT_FAILED', class: 'fc-tag-charging', key: 'PAYMENT_FAILED', color: '#B43434' },
  { label: '充电异常', value: 'CHARGING_EXCEPTION', class: 'fc-tag-charging', key: 'CHARGING_EXCEPTION', color: '#B43434' },
  { label: '关闭', value: 'CLOSED', class: 'fc-tag-charging', key: 'CLOSED', color: '#AAAAAA' },
]
export const Resource_Type = {
  feedback: 'feedback',
  comment: 'comment'
};

export const Transaction_Type = {
  WALLET_RECHARGE: 'WALLET_RECHARGE',
  CHARGING: 'CHARGING',
  WALLET_WITHDRAW: 'WALLET_WITHDRAW',
  Map: {
    CHARGING: '充电消费',
    WALLET_RECHARGE: '钱包充值',
    WALLET_WITHDRAW: '钱包提现'
  },
  Options: [
    { label: '充电消费', value: 'CHARGING' },
    { label: '钱包充值', value: 'WALLET_RECHARGE' },
    { label: '钱包提现', value: 'WALLET_WITHDRAW' }
  ]
};
//支付方式： WECHAT("WECHAT", "微信支付"), ALIPAY("ALIPAY", "支付宝"), WALLET("WALLET", "钱包"), PLATFORM_WALLET("PLATFORM_WALLET","平台专属钱包"), WECHAT_RECHARGE("WECHAT_RECHARGE","微信充值");
export const Payment_Type = {
  Map: {
    WECHAT: '微信支付',
    ALIPAY: '支付宝',
    WALLET: '钱包'
  },
  MapNew: {
    WECHAT: '微信支付',
    ALIPAY: '支付宝',
    WALLET: '钱包',
    WECHAT_APP:'微信APP支付',
    PLATFORM_WALLET:'平台专属钱包',
    VIP:'VIP'
  },
  Options: [
    { label: '微信支付', value: 'WECHAT' },
    { label: '支付宝', value: 'ALIPAY' },
    { label: '钱包', value: 'WALLET' }
  ]
};
export const orderType ={
  Map: {
    1:'先付后充',
    2:'先充后付'
  }
}
export const Role_Type = {
  Map: {
    SYSTEM: '系统',
    AGENT: '经销商'
  },
  Options: [
    { label: '系统', value: 'SYSTEM' },
    { label: '经销商', value: 'AGENT' }
  ]
};

export const Advert_Company_Status = {
  Map: {
    active: '合作中',
    inactive: '未合作',
    blacklist: '黑名单'
  },
  Options: [
    { label: '合作中', value: 'active' },
    { label: '未合作', value: 'inactive' },
    { label: '黑名单', value: 'blacklist' }
  ]
};

export const Advert_Content_Type = {
  Map: {
    1: '图片',
    2: '文字',
    0: '视频'
  },
  Options: [
    { label: '图片', value: '1' },
    { label: '文字', value: '2' },
    { label: '视频', value: '0' }
  ]
};

export const Advert_Status = {
  approved: 'approved',
  rejected: 'rejected',
  Map: {
    pending: '未审核',
    approved: '已通过',
    rejected: '已拒绝'
  },
  Options: [
    { label: '未审核', value: 'pending' },
    { label: '已通过', value: 'approved' },
    { label: '已拒绝', value: 'rejected' }
  ]
};

export const Avert_Program_Play_Location = {
  Map: ['中间大屏', '滚动条'],
  Options: [
    { label: '中间大屏', value: '0' },
    { label: '滚动条', value: '1' }
  ]
};

export const Avert_Program_Play_Type = {
  Map: ['循环播放', '单次播放'],
  Options: [
    { label: '循环播放', value: '0' },
    { label: '单次播放', value: '1' }
  ]
};

export const Avert_Task_Scope = {
  Map: ['所有的充电桩', '部分充电桩'],
  Options: [
    { label: '所有的充电桩', value: '0' },
    { label: '部分充电桩', value: '1' }
  ]
};

export const Avert_Task_Usage_Status = {
  Map: ['未使用', '使用中'],
  Options: [
    { label: '未使用', value: 0 },
    { label: '使用中', value: 1 }
  ]
};

export const Gun_Status = {
  Map: {
    offline: '离线',
    free: '空闲中',
    locked: '被锁定',
    in_use: '使用中',
    abnormal: '故障',
    finish_occupied: '充电完成，但枪被占用'
  },
  Options: [
    { label: '空闲中', value: 'free' },
    { label: '被锁定', value: 'locked' },
    { label: '使用中', value: 'in_use' },
    { label: '故障', value: 'abnormal' },
    { label: '充电完成，但枪被占用', value: 'finish_occupied' }
  ]
};

export const Potin_status = {
  Map: {
    free: '空闲中',
    locked: '被锁定',
    in_use: '使用中',
    abnormal: '故障'
  }
};

export const OTA_Scope = {
  portion: 'portion',
  all: 'all',
  Map: {
    portion: '局部',
    all: '全局'
  },
  Options: [
    { label: '局部', value: 'portion' },
    { label: '全局', value: 'all' }
  ]
};
export const OTA_PUblish_Status={
  Map: {
    completed: '已完成',
    no_start: '未开始',
    upgrading: '升级中',
    cancelled: '已取消',
    failed:'升级失败'
  },
  Options: [
    { label: '已完成', value: 'completed' },
    { label: '未开始', value: 'no_start' },
    { label: '升级中', value: 'upgrading' },
    { label: '已取消', value: 'cancelled' },
    { label: '升级失败', value: 'failed' },
  ]
}
export const OTA_Package_Type = {
  Map: {
    ota_screen: '主屏升级包',
    ota_charging_board: '充电板',
    ota_charging_board_v2: '充电板V2',
    ota_driver_board: '主屏升级包'
  },
  Options: [
    { label: '主屏升级包', value: 'ota_screen' },
    { label: '充电板', value: 'ota_charging_board' },
    { label: '充电板V2', value: 'ota_charging_board_v2' },
    { label: '电动驱动版', value: 'ota_driver_board' }
  ]
};
/**
 * ota升级包管理状态字段
 * @type {{Options: [{label: string, value: string},{label: string, value: string}], Map: {unused: string, used: string}}}
 */
export const OTA_Package_Status_New={
  Map: {
    used: '已使用',
    unused: '未使用',
  },
  Options: [
    { label: '已使用', value: 'used' },
    { label: '未使用', value: 'unused' },
  ]
}
export const OTA_Package_Status = {
  approved: 'approved',
  Map: {
    pending_review: '待审核',
    reviewing: '审核中',
    approved: '审核通过',
    rejected: '审核未通过'
  },
  Options: [
    { label: '待审核', value: 'pending_review' },
    { label: '审核中', value: 'reviewing' },
    { label: '审核通过', value: 'approved' },
    { label: '审核未通过', value: 'rejected' }
  ]
};

export const OTA_Release_Type = {
  testing: 'testing',
  production: 'production',
  Map: {
    testing: '测试发布',
    production: '正式发布'
  },
  Options: [
    { label: '测试发布', value: 'testing' },
    { label: '正式发布', value: 'production' }
  ]
};

export const OTA_TASK_STATUS = {
  unissued: 'unissued',
  issued: 'issued',
  portion_issued: 'portion_issued',
  success: 'success',
  failed: 'failed',
  Map: {
    unissued: '未下发',
    issued: '已下发',
    success: '执行成功',
    failed: '执行失败',
    portion_issued: '部分下发'
  },
  Options: [
    { label: '未下发', value: 'unissued' },
    { label: '已下发', value: 'issued' },
    { label: '执行成功', value: 'success' },
    { label: '执行失败', value: 'failed' },
    { label: '部分下发', value: 'portion_issued' }
  ]
};
export const OTA_TASK_STATUS_NEW = {
  unissued: 'unissued',
  issued: 'issued',
  success: 'success',
  failed: 'failed',
  Map: {
    unissued: '未下发',
    issued: '已下发',
    success: '升级成功',
    failed: '升级失败',
  },
  Options: [
    { label: '未下发', value: 'unissued' },
    { label: '已下发', value: 'issued' },
    { label: '升级成功', value: 'success' },
    { label: '升级失败', value: 'failed' },
  ]
};
export const Home_Scope = {
  month: 'month',
  year: 'year',
  week: 'week'
};

export const Coupon_Category = {
  manjian: 'manjian',
  zhekou: 'zhekou',
  lijian: 'lijian',
  Map: {
    manjian: '满减券',
    zhekou: '折扣券',
    lijian: '立减券'
  },
  Options: [
    { label: '满减券', value: 'manjian' },
    { label: '折扣券', value: 'zhekou' },
    { label: '立减券', value: 'lijian' }
  ]
};

export const Agent_Withdrawal_Status = {
  draft: 'draft',
  pending_review: 'pending_review',
  approved: 'approved',
  rejected: 'rejected',
  payed: 'payed',
  Map: {
    draft: '草稿',
    pending_review: '待审核',
    approved: '待支付',
    rejected: '审核未通过',
    payed: '已经支付'
  },
  Options: [
    { label: '草稿', value: 'draft' },
    { label: '待审核', value: 'pending_review' },
    { label: '待支付', value: 'approved' },
    { label: '审核未通过', value: 'rejected' },
    { label: '已经支付', value: 'payed' }
  ]
};

export const Coupon_Template_Status = {
  in_effect: 'in_effect',
  un_effect: 'un_effect',
  expired: 'expired',
  discard: 'discard',
  in_review:'in_review',
  review_failed:'review_failed',
  Map: {
    in_effect: '生效中',
    un_effect: '待提交',
    expired: '已过期',
    discard: '已作废',
    in_review:'审核中',
    review_failed:'审核失败',
  },

  Options: [
    { label: '生效中', value: 'in_effect' },
    { label: '待提交', value: 'un_effect' },
    { label: '已过期', value: 'expired' },
    { label: '已作废', value: 'discard' },
    { label: '审核中', value: 'in_review' },
    { label: '审核失败', value: 'review_failed' },
  ],
  CouponType:[
    { label: '生效中', value: 'in_effect',key:'in_effect', color: '#3F93FF' },
    { label: '待提交', value: 'un_effect',key:'un_effect', color: '#32D380' },
    { label: '充电中', value: 'in_use', class: 'fc-tag-charging', key: 'in_use', color: '#3F93FF' },
    { label: '已过期', value: 'expired',key:'expired', color: '#999999' },
    { label: '已作废', value: 'discard',key:'discard', color: '#B43434' },
    { label: '审核中', value: 'in_review',key:'in_review', color: '#FAC858' },
    { label: '审核失败', value: 'review_failed',key:'review_failed', color: '#B43434' },
  ]
};
export const Coupon_Recharge_Status = {
  // in_effect: 'in_effect',
  // un_effect: 'un_effect',
  // expired: 'expired',
  // discard: 'discard',
  un_audited:'un_audited',// 待提交,
  in_audited:'in_audited',// 审核中,
  un_pass:'un_pass',// 审核失败,
  un_effect:'un_effect',// 待生效（审核通过）,
  in_effect:'in_effect',// 生效中,
  expired:'expired',// 已过期,
  Map: {
    un_audited:'待提交',
    in_audited:'审核中',
    un_pass:'审核失败',
    un_effect:'待生效',
    in_effect:'生效中',
    expired:'已过期',
    off_shelves:'已下架',
  },
  buttonC:[
    { label: '待提交', value: 'un_audited', class: 'fc-tag-empty', key: 'un_audited', color: '#AAAAAA' },
    { label: '审核中', value: 'in_audited', class: 'fc-tag-empty', key: 'in_audited', color: '#FF8400' },
    { label: '审核失败', value: 'un_pass', class: 'fc-tag-empty', key: 'un_pass', color: '#B43434' },
    { label: '待生效', value: 'un_effect', class: 'fc-tag-charging', key: 'un_effect', color: '#FF8400' },
    { label: '生效中', value: 'in_effect', class: 'fc-tag-charging', key: 'in_effect', color: '#32D380' },
    { label: '已过期', value: 'expired', class: 'fc-tag-charging', key: 'expired', color: '#AAAAAA' },
    { label: '已下架', value: 'off_shelves', class: 'fc-tag-charging', key: 'off_shelves', color: '#6E7079' },
  ],
  Options: [
    {label:'待提交',value:'un_audited'},
    {label:'审核中',value:'in_audited'},
    {label:'审核失败',value:'un_pass'},
    {label:'待生效',value:'un_effect'},
    {label:'生效中',value:'in_effect'},
    {label:'已过期',value:'expired'},
    {label:'已下架',value:'off_shelves'},
  ],
  CouponType:[
    {label:'待提交',value:'un_audited',key:'un_audited'},
    {label:'审核中',value:'in_audited',key:'in_audited'},
    {label:'审核失败',value:'un_pass',key:'un_pass'},
    {label:'待生效',value:'un_effect',key:'un_effect'},
    {label:'生效中',value:'in_effect',key:'in_effect'},
    {label:'已过期',value:'expired',key:'expired'},
    // { label: '生效中', value: 'in_effect',key:'in_effect', color: '#3F93FF' },
    // { label: '待审核', value: 'un_effect',key:'un_effect', color: '#F3BD67' },
    // { label: '充电中', value: 'in_use', class: 'fc-tag-charging', key: 'in_use', color: '#3F93FF' },
    // { label: '已过期', value: 'expired',key:'expired', color: '#999999' },
    // { label: '已作废', value: 'discard',key:'discard', color: '#B3B3B3' }
  ]
};

export const Invoice_Type = {
  Map: {
    PERSONAL: '个人',
    ENTERPRISE: '企业'
  },
  Options: [
    { label: '个人', value: 'PERSONAL' },
    { label: '企业', value: 'ENTERPRISE' }
  ]
};

export const Invoice_Status = {
  Map: {
    2: '开票成功',
    22: '开票失败',
    20: '正在开票'
  },
};
export const Invoice_Pay_Type = {
  Map: {
    WECHAT: '微信支付',
    WALLET: '钱包支付'
  },
  Options: [
    { label: '微信支付', value: 'WECHAT' },
    { label: '钱包支付', value: 'WALLET' }
  ]
};

export const Pile_Faule_Type = {
  Map: {
    1: '过压',
    2: '欠压',
    3: '过流(一级)',
    4: '过流(二级)',
    5: '接地错误',
    6: '漏电错误',
    7: '继电器器错误',
    8: 'CP错误',
    9: '⻋内组件错误',
    10: '电机异常',
    11: '升降结构异常',
    12: '其它错误',
    13: '设备频繁掉线',
    101: '急停按钮动作故障',
    102: '无可用整流模块',
    103: '出风口温度过高',
    104: '交流防雷故障',
    105: '交直流模块DC20通信中断',
    106: '绝缘检测模块FC08通信中断',
    107: '电度表通信中断',
    108: '读卡器通信中断',
    109: 'RC10通信中断',
    110: '风扇调速板故障',
    111: '直流熔断器故障',
    112: '高压接触器故障',
    113: '门打开',
    114: '其他故障',


    20: 'L,N连接故障',
    21: '接地故障',
    22: 'PCB板故障(返厂维修)',
    23: '过压',
    24: '欠压',
    25: '过流1级',
    26: '过流2级',
    27: '漏电',
    28: '继电器过温',
    29: 'CP故障',
    31: '移门没有完全打开',
    32: '移门没有完全关闭',
    33: '小车没有回到初始位置',
    34: '小车没有到达指定位置',
    35: '枪没有回收',
    41: '车端组件错误',
    51: '电机供电电压低',
    52: '电机供电电压高',
    53: '小车电机过流',
    54: '移门电机过流',
    55: '卷线电机过流',
    56: '卷线电机编码器失效(返厂维修)',

  },
  Options: [
    { value: 1, label: '过压' },
    { value: 2, label: '欠压' },
    { value: 3, label: '过流(一级)' },
    { value: 4, label: '过流(二级)' },
    { value: 5, label: '接地错误' },
    { value: 6, label: '漏电错误' },
    { value: 7, label: '继电器器错误' },
    { value: 8, label: 'CP错误' },
    { value: 9, label: '车内组件错误' },
    { value: 10, label: '电机异常' },
    { value: 11, label: '升降结构异常' },
    { value: 12, label: '其它错误' },
  ],
  Options2: [
    {value:20, label: 'L,N连接故障'},
    {value:21, label: '接地故障'},
    {value:22, label: 'PCB板故障(返厂维修)'},
    {value:23, label: '过压'},
    {value:24, label: '欠压'},
    {value:25, label: '过流1级'},
    {value:26, label: '过流2级'},
    {value:27, label: '漏电'},
    {value:28, label: '继电器过温'},
    {value:29, label: 'CP故障'},
    {value:31, label: '移门没有完全打开'},
    {value:32, label: '移门没有完全关闭'},
    {value:33, label: '小车没有回到初始位置'},
    {value:34, label: '小车没有到达指定位置'},
    {value:35, label: '枪没有回收'},
    {value:41, label: '车端组件错误'},
    {value:51, label: '电机供电电压低'},
    {value:52, label: '电机供电电压高'},
    {value:53, label: '小车电机过流'},
    {value:54, label: '移门电机过流'},
    {value:55, label: '卷线电机过流'},
    {value:56, label: '卷线电机编码器失效(返厂维修)'},
  ]
};

export const App_Advert_Type = {
  external: 'external',
  Map: {
    external: '外部广告',
    topup: '充值广告',
    coupon: '优惠券广告'
  },
  Options: [
    { value: 'external', label: '外部广告' },
    { value: 'topup', label: '充值广告' },
    { value: 'coupon', label: '优惠券广告' }
  ]
};

export const Workflow_Type = {
  station_charge_details: 'station_charge_details',
  add_user: 'add_user',
  edit_user: 'edit_user',
  delete_user: 'delete_user',
  edit_role_perms: 'edit_role_perms',
  recharge_activity: 'recharge_activity',
  coupon_template:'coupon_template',
  Map: {
    station_charge_details: '充电站收费明细',
    add_user: '添加用户',
    edit_user: '编辑用户',
    delete_user: '删除用户',
    edit_role_perms: '编辑角色权限',
    recharge_activity: '充值优惠更新',
    coupon_template: '优惠券模版'
  },
  Options: [
    { value: 'station_charge_details', label: '充电站收费明细' },
    { value: 'coupon_template', label: '优惠券模版' },
    { value: 'add_user', label: '添加用户' },
    { value: 'edit_user', label: '编辑用户' },
    { value: 'delete_user', label: '删除用户' },
    { value: 'edit_role_perms', label: '编辑角色权限' },
    { value: 'recharge_activity', label: '充值优惠更新' }
  ]
};

export const Workflow_Status = {
  draft: 'draft',
  submitted: 'submitted',
  approved: 'approved',
  rejected: 'rejected',
  closed: 'closed',
  Map: {
    draft: '草稿',
    submitted: '已提交',
    approved: '已通过',
    rejected: '已拒绝',
    closed: '已关闭'
  },
  Options: [
    { value: 'draft', label: '草稿' },
    { value: 'submitted', label: '已提交' },
    { value: 'approved', label: '已通过' },
    { value: 'rejected', label: '已拒绝' },
    { value: 'closed', label: '已关闭' }
  ]
};

export const Workflow_Base_Mode = {
  all: 'all',
  pending: 'pending',
  done: 'done'
};

export const Order_Pay_Time_Type = {
  Map: {
    Less_24_Hour: 'ONE_DAY',
    Less_48_Hour: 'TWO_DAYS',
    More_48_Hour: 'MORE',
    Charging: 'CHARGING'
  },
  Options: [
    { value: 'ONE_DAY', label: '小于24小时' },
    { value: 'TWO_DAYS', label: '24小时至48小时' },
    { value: 'MORE', label: '大于48小时' }
  ]
};
// 产品类型
export const Product_Type = {
  Map: {
    1: '电表'
  },
  Options: [
    { value: 1, label: '电表' }
  ]
};
// 设备类型
export const Device_Type = {
  Options: [
    { value: 'dc_quick', label: '直流快充' },
    { value: 'dc_slow', label: '直流慢充' },
    { value: 'ac_quick', label: '交流快充' },
    { value: 'ac_slow', label: '交流慢充' }
  ]
};
// 状态
export const Status_Type = {
  Map: {
    1: '上架',
    0: '下架'
  },
  Options: [
    { value: 0, label: '下架' },
    { value: 1, label: '上架' }
  ]
};

export const Banch_Type = {
  Map: {
    Y: '已对账',
    N: '未对账'
  }
};

export const Tcec_Order_Status = {
  Map: {
    NORMAL:'正常',
    EXCEPTION:'异常',
  }
};

export const Order_By_Type = {
  Map: {
    0:'乐轶',//输入自营
    1:'度普',
    2:'乐轶',
    3:'启垠'
  },
  buttonC:[
    { label: '乐轶', value: '0', class: 'fc-tag-empty', key: '0', color: '#6596DF' },
    { label: '度普', value: '1', class: 'fc-tag-empty', key: '1', color: '#6596DF' },
    { label: '乐轶', value: '2', class: 'fc-tag-empty', key: '2', color: '#6596DF' },
    { label: '启垠', value: '3', class: 'fc-tag-charging', key: '3', color: '#6596DF' },
  ]
};
export const Order_Type = {
  Map:{
    'A2KPGBA0R':'乐轶',
    'MA27U0C07':'安培',
    'MA1GTYKU7':'度普',
    '91310104M':'便利电'
  },
  buttonD:[
    { label: '乐轶', value: 'A2KPGBA0R', class: 'fc-tag-empty', key: 'A2KPGBA0R', color: '#6596DF' },
    { label: '安培', value: 'MA27U0C07', class: 'fc-tag-empty', key: 'MA27U0C07', color: '#6596DF' },
    { label: '度普', value: 'MA1GTYKU7', class: 'fc-tag-empty', key: 'MA1GTYKU7', color: '#6596DF' },
    { label: '便利电', value: '91310104M', class: 'fc-tag-empty', key: '91310104M', color: '#6596DF' },
  ]
};

export const ExportStatus_Type = {
  Map: {
    3: '失败',
    2: '完成',
    1: '正在生成数据',
    0: '排队中'
  },
  Options: [
    { value: 0, label: '排队中' },
    { value: 1, label: '正在生成数据' },
    { value: 2, label: '完成' },
    { value: 3, label: '失败' }
  ]
};
export const productPile_Type = {

}
export const productPile_State = {
  Map: {
    online: '在线',
    offline: '离线',
    stop: '停用',
    abnormal: '故障'
  },
  class:{
    online: 'primary',
    offline: 'primary',
    stop: 'primary',
    abnormal: 'primary'
  }
}

export const productPile_run_status = {
  Map: {
    free:'空闲',
    partial_free:'空余',
    in_use:'充电中',
    abnomal:'故障',
    offline:'离线',
    locked:'锁定'
  }
}

export const Product_Product_Type = {
  Map: {
    '1': '充电桩',
    '2': '充电枪',
    '3': '二维码',
    '4': '电表',
  },
  Options: [
    {label:'充电桩',value:'1',},
    {label:'充电枪',value:'2',},
    {label:'二维码',value:'3',},
    {label:'电表',value:'4',}
  ]
};

export const Product_Product_Status = {
  Map: {
    0: '下架中',
    1: '上架中',
  },
  Options: [
    {label:'下架中',value:0,},
    {label:'上架中',value:1,},
  ]
};
export const Device_Allocate_Status = {
  Map: {
    0:'未分配',
    1:'已分配'
  },
  Options:[
    {value:0,label:'未分配'},
    {value:1,label:'已分配'}
  ]
}
export const Device_Status = {
  Map:{
    '0':'离线',
    '1':'在线',
    '2':'停用',
    '255':'故障',
  },
  Options:[
    { label:"停用", value:2 },
    { label:"在线", value:1 },
    { label:"故障", value:255 },
    { label:"离线", value:0 }
  ],
}
/**
 * 运行状态
 * @type {{Options: [{label: string, value: number},{label: string, value: number},{label: string, value: number},{label: string, value: number},{label: string, value: number},null]}}
 */
export const Running_Status={
  Options:[
    {label:'离线',value:0},
    {label:'空闲',value:1},
    {label:'空余',value:2},
    {label:'充电中',value:3},
    {label:'升级中',value:4},
    {label:'故障',value:255},
  ],
  Map:{
    'offline':'离线',
    'free':'空闲',
    'partial_free':'空余',
    'in_use':'充电中',
    'upgrading':'升级中',
    'abnormal':'故障',
  },
  buttonC:[
    { label: '离线', value: '离线', class: 'fc-tag-empty', key: '离线', color: '#AAAAAA' },
    { label: '空闲', value: '空闲', class: 'fc-tag-empty', key: '空闲', color: '#32D380' },
    { label: '空余', value: '空余', class: 'fc-tag-empty', key: '空余', color: '#3F93FF' },
    { label: '充电中', value: '充电中', class: 'fc-tag-charging', key: '充电中', color: '#3F93FF' },
    { label: '升级中', value: '升级中', class: 'fc-tag-charging', key: '升级中', color: '#FF8400' },
    { label: '故障', value: '故障', class: 'fc-tag-charging', key: '故障', color: '#B43434' },
  ]
}
/**
 * 设备状态
 * @type {{Options: [{label: string, value: number},{label: string, value: number},{label: string, value: number},{label: string, value: number},{label: string, value: number},null,null,null,null,null,null]}}
 */
export const Device_Status_New={
  Options:[
    {label:'空闲（包在初始位置）',value:"NEW"},
    {label:'包向目标位置移动，移门打开',value:"CHARGING_PREPARING"},
    {label:'包移动到指定位置，枪下放',value:"CHARGING_PREPARING"},
    {label:'枪下放到位',value:"CHARGING_PREPARING"},
    {label:'枪插到车上（没有充电）',value:"CHARGING_IN_PROGRESS"},
    {label:'开始充电（充电中）',value:"CHARGING_IN_PROGRESS"},
    {label:'结束充电',value:"CHARGING_COMPLETE"},
    {label:'枪拔出',value:"PAYMENT_PENDING"},
    {label:'枪回收',value:"PAYMENT_PENDING"},
    {label:'枪回收中断',value:"PAYMENT_PENDING"},
    {label:'枪收回到包里，包向初始位置移动，移门关闭',value:"PAYMENT_PENDING"},
    {label:'故障（充电桩不可用）',value:"CHARGING_PREPARING_FAILED"},
  ]
}

/**
 * 故障监控-故障状态
 * @type {{Options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], Map: {"": string, Y: string, N: string}}}
 */
export const Fault_Status={
  Options:[
    {label:'全部',value:''},
    {label:'未处理',value:'N'},
    {label:'已处理',value:'Y'}
  ],
  Map:{
    '':'全部',
    'N':'未处理',
    'Y':'已处理',
  }
}
/**
 * 故障监控-处理标记
 * @type {{Options: [{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},null], Map: {"": string, "0": string, "1": string, "2": string, "3": string, "4": string}}}
 */
export const Fault_Handle_Mark={
  CouponType:[
    { label: '未处理', value: '0',key:'0', color: '#F3BD67' },
    { label: '自动处理成功', value: '1',key:'1', color: '#3F93FF' },
    { label: '手动处理成功', value: '2',key:'2', color: '#3F93FF' },
    { label: '流程处理', value: '3',key:'3', color: '#3F93FF' },
    { label: '自动恢复', value: '4',key:'4', color: '#3F93FF' },
    { label: '自动处理失败', value: '5',key:'5', color: '#F56C6C' },
    { label: '手动处理失败', value: '6',key:'6', color: '#F56C6C' },
    { label: '生成工单（未处理）', value: '7',key:'7', color: '#67C23A' },
    { label: '生成工单（自动处理成功）', value: '8',key:'8', color: '#3F93FF' },
    { label: '生成工单（自动处理失败）', value: '9',key:'9', color: '#F56C6C' },
    { label: '生成工单（手动处理成功）', value: '10',key:'10', color: '#3F93FF' },
    { label: '生成工单（手动处理失败）', value: '11',key:'11', color: '#F56C6C' },
    { label: '工单处理成功', value: '12',key:'12', color: '#3F93FF' },
  ],
  Options:[
    {label:'全部',value:''},
    {label:'未处理',value:'0'},
    {label:'自动处理成功',value:'1'},
    {label:'手动处理成功',value:'2'},
    {label:'流程处理',value:'3'},
    {label:'自动恢复',value:'4'},
    {label:'自动处理失败',value:'5'},
    {label:'手动处理失败',value:'6'},
    {label:'生成工单（未处理）',value:'7'},
    {label:'生成工单（自动处理成功）',value:'8'},
    {label:'生成工单（自动处理失败）',value:'9'},
    {label:'生成工单（手动处理成功）',value:'10'},
    {label:'生成工单（手动处理失败）',value:'11'},
    {label:'工单处理成功',value:'12'},
  ],
  Map:{
    '':'全部',
    '0':'未处理',
    '1':'自动处理成功',
    '2':'手动处理成功',
    '3':'流程处理',
    '4':'自动恢复',
    '5':'自动处理失败',
    '6':'手动处理失败',
    '7':'生成工单（未处理）',
    '8':'生成工单（自动处理成功）',
    '9':'生成工单（自动处理失败）',
    '10':'生成工单（手动处理成功）',
    '11':'生成工单（手动处理失败）',
    '12':'工单处理成功',
  }
}
/**
 * 工单状态
 * @type {{Options: [{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}], Map: {PENDING_PROCESSING: string, COMPLETE: string, PROCESSING: string, TO_BE_CONFIRMED: string}}}
 */
export const Work_Order_State={
  Options:[
    {label:'待确认',value:'TO_BE_CONFIRMED'},
    {label:'待处理',value:'PENDING_PROCESSING'},
    {label:'处理中',value:'PROCESSING'},
    {label:'完成',value:'COMPLETE'},
  ],
  Map:{
    'TO_BE_CONFIRMED':'待确认',
    'PENDING_PROCESSING':'待处理',
    'PROCESSING':'处理中',
    'COMPLETE':'完成',
  },
  buttonC:[
    { label: '待确认', value: 'TO_BE_CONFIRMED', class: 'fc-tag-empty', key: 'TO_BE_CONFIRMED', color: '#ff8300' },
    { label: '待处理', value: 'PENDING_PROCESSING', class: 'fc-tag-empty', key: 'PENDING_PROCESSING', color: '#ef6ddd' },
    { label: '处理中', value: 'PROCESSING', class: 'fc-tag-empty', key: 'PROCESSING', color: '#337ecc' },
    { label: '已完成', value: 'COMPLETE', class: 'fc-tag-charging', key: 'COMPLETE', color: '#529b2e' },
  ]
}
/**
 * 转单申请状态
 * @type {{Options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], buttonC: [{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string}], Map: {COMPLETE: string, PROCESSING: string, REFUSE: string}}}
 */
export const Transfer_Order_State={
  Options:[
    {label:'待处理',value:'PENDING_PROCESSING'},
    {label:'已转单',value:'COMPLETE'},
    {label:'已拒绝',value:'REJECTED'},
  ],
  Map:{
    "PENDING_PROCESSING": "待处理",
    "COMPLETE":"已转单",
    "REJECTED": "已拒绝",
  },
  buttonC:[
    { label: '待处理', value: 'PENDING_PROCESSING', class: 'fc-tag-empty', key: 'PENDING_PROCESSING', color: '#337ecc' },
    { label: '已转单', value: 'COMPLETE', class: 'fc-tag-empty', key: 'COMPLETE', color: '#AAAAAA' },
    { label: '已拒绝', value: 'REJECTED', class: 'fc-tag-empty', key: 'REJECTED', color: '#B43434' },
  ]
}
/**
 * 待支持工单
 * @type {{Options: [{label: string, value: string},{label: string, value: string}], buttonC: [{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string}], Map: {COMPLETE: string, PROCESSING: string}}}
 */
export const Un_Support_State={
  Options:[
    {label:'待处理',value:'PENDING_PROCESSING'},
    {label:'已处理',value:'COMPLETE'},
  ],
  Map:{
    "PENDING_PROCESSING": "待处理",
    "COMPLETE":"已处理",
  },
  buttonC:[
    { label: '待处理', value: 'PENDING_PROCESSING', class: 'fc-tag-empty', key: 'PENDING_PROCESSING', color: '#ff8300' },
    { label: '已处理', value: 'COMPLETE', class: 'fc-tag-empty', key: 'COMPLETE', color: '#AAAAAA' },
  ]
}
/**
 * 工厂任务列表状态
 * @type {{Options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], buttonC: [{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string}], Map: {PENDING_PROCESSING: string, COMPLETE: string, PROCESSING: string}}}
 */
export const Factory_Tasks_State={
  Options:[
    {label:'待处理',value:'PENDING_PROCESSING'},
    {label:'处理中',value:'PROCESSING'},
    {label:'已完成',value:'COMPLETE'},
  ],
  Map:{
    "PENDING_PROCESSING": "待处理",
    "PROCESSING":"处理中",
    "COMPLETE":"已完成",
  },
  buttonC:[
    { label: '待处理', value: 'PENDING_PROCESSING', class: 'fc-tag-empty', key: 'PENDING_PROCESSING', color: '#f16cdf' },
    { label: '处理中', value: 'PROCESSING', class: 'fc-tag-empty', key: 'PROCESSING', color: '#3f93ff' },
    { label: '已完成', value: 'COMPLETE', class: 'fc-tag-empty', key: 'COMPLETE', color: '#32d380' },
  ]
}
/**
 * 责任判断值
 * @type {{Options: [{label: string, value: string},{label: string, value: string}]}}
 */
export const Responsibility_Value={
  Options:[
    {label:'设备质量',value:'EQUIPMENT_QUALITY'},
    {label:'施工问题',value:'CONSTRUCTION_PROBLEMS'},
  ],
  Map:{
    "EQUIPMENT_QUALITY": "设备质量",
    "CONSTRUCTION_PROBLEMS":"施工问题",
  },
}
/**
 * 更换设备方式
 * @type {{Options: [{label: string, value: string},{label: string, value: string}]}}
 */
export const Change_Device_Type={
  Options:[
    {label:'邮寄',value:'MAIL'},
    {label:'自提',value:'SELF_PICKUP'},
  ],
  Map:{
    "MAIL": "邮寄",
    "SELF_PICKUP":"自提",
  },
}
/**
 * 解决方案
 * @type {{Options: [{label: string, value: string},{label: string, value: string}]}}
 */
export const Solve_Programme={
  Options:[
    {label:'更换设备',value:'REPLACEMENT_EQUIPMENT'},
    {label:'维修',value:'MAINTENANCE'},
  ],
  Map:{
    "REPLACEMENT_EQUIPMENT": "更换设备",
    "MAINTENANCE":"维修",
  },
}

export const User_Status={
  Options:[
    {label:'全部',value: ''},
    {label:'冻结',value:'1'},
    {label:'正常',value:'0'},
  ],
  Map:{
    "": "全部",
    "1":"冻结",
    "0":"正常",
  },
  buttonC:[
    { label: '冻结', value: '1', class: 'fc-tag-empty', key: '1', color: '#B43434' },
    { label: '正常', value: '0', class: 'fc-tag-empty', key: '0', color: '#3F93FF' },
  ]
}
/**
 * 分账状态
 * @type {{Options: [{label: string, value: string},{label: string, value: string}], buttonC: [{color: string, label: string, value: string, class: string, key: string},{color: string, label: string, value: string, class: string, key: string}], Map: {"1": string, "2": string}}}
 */
export const Divide_Status={
  Options:[
    {label:'已发起',value:'INITIATED'},
    {label:'发起失败',value:'INITIATE_FAILED'},
    {label:'余额不足',value:'LOW_BALANCE'},
    {label:'分账成功',value:'SPLIT_SUC'},
    {label:'分账失败',value:'SPLIT_FAILED'},
  ],
  Map:{
    "INITIATED":"已发起",
    "INITIATE_FAILED":"发起失败",
    "LOW_BALANCE":"余额不足",
    "SPLIT_SUC":"分账成功",
    "SPLIT_FAILED":"分账失败",
  },
  buttonC:[
    { label: '已发起', value: 'INITIATED', class: 'fc-tag-empty', key: 'INITIATED', color: '#32D380' },
    { label: '发起失败', value: 'INITIATE_FAILED', class: 'fc-tag-empty', key: 'INITIATE_FAILED', color: '#B43434' },
    { label: '余额不足', value: 'LOW_BALANCE', class: 'fc-tag-empty', key: 'LOW_BALANCE', color: '#3F93FF' },
    { label: '分账成功', value: 'SPLIT_SUC', class: 'fc-tag-empty', key: 'SPLIT_SUC', color: '#32D380' },
    { label: '分账失败', value: 'SPLIT_FAILED', class: 'fc-tag-empty', key: 'SPLIT_FAILED', color: '#B43434' },
  ]
}
/**
 * 分账模式
 * @type {{Options: [{label: string, value: number},{label: string, value: number},{label: string, value: number}], Map: {1: string, 2: string, 3: string}}}
 */
export const Divide_Mode={
  Options:[
    {label:'未开通',value:1},
    {label:'已开通',value:2},
    {label:'已关闭',value:3},
  ],
  Map:{
    1:"未开通",
    2:"已开通",
    3:"已关闭",
  },
}
/**
 *分红模式
 * @type {{Options: [{label: string, value: number},{label: string, value: number},{label: string, value: number}], Map1: {1: string, 2: string, 3: string}, Options1: [{label: string, value: number},{label: string, value: number},{label: string, value: number}], Map: {1: string, 2: string, 3: string}}}
 */
export const Divvy_Mode={
  Options:[
    {label:'按服务费比例分红',value:1},
    {label:'按度数分红',value:2},
    {label:'按订单分红',value:3},
    {label:'按总额比例分红',value:4},
  ],
  Options1:[
    {label:'%',value:1},
    {label:'元/度',value:2},
    {label:'元/订单',value:3},
    {label:'%',value:4},
  ],
  Map:{
    1:"按服务费比例分红",
    2:"按度数分红",
    3:"按订单分红",
    4:"按总额比例分红",
  },
  Map1:{
    1:"%",
    2:"元/度",
    3:"元/订单",
    4:"%",
  },
}